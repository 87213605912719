import React from "react";
import { Card, Col, Container, Row, Image, Nav } from "react-bootstrap";
import PlayStore from "./assets/images/play-store.png";
import AppStore from "./assets/images/app-store.png";
import Logo from "./assets/images/logo.png";

function App() {
  return (
    <Container fluid className="vh-100">
      <Row className="vh-100 align-items-center">
        <Col md={{ span: 4, offset: 4 }}>
          <h6 className="text-center">Pamo App</h6>
          <Card className="border-0 shadow mt-4">
            <Card.Body className="text-center p-5">
              <Nav.Link href="https://pamo.app">
                <Image src={Logo} className="rounded-4" width="100px" />
              </Nav.Link>

              <h5 className="mt-5">Pamo: Want it. Post it. Get it.</h5>

              <p className="mt-4 mb-5 fw-light text-secondary">
                On Pamo, you can post a picture or details of an item you are
                looking to buy, stating your budget. Pamo will search its vast
                network of sellers, manufacturers, agents, etc to find the exact
                item and deliver to you.
                <br />
                Download and sign up to enjoy.
              </p>

              <a href="https://apps.apple.com/us/app/pamo/id1615449718?uo=4">
                <Image src={AppStore} width="200px" fluid />
              </a>
              <br />
              <br />
              <a href="https://play.google.com/store/apps/details?id=app.pamo">
                <Image src={PlayStore} width="200px" fluid />
              </a>
            </Card.Body>
          </Card>

          <p className="text-center mt-3">
            {" "}
            <a className="text-muted" href="https://pamo.app">
              pamo.app
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
